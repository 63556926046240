
//https://jyushin-backend.herokuapp.com/explorer/#!/AnnoAccademico/AnnoAccademico_create
//export const protocol= "https"; 
//export const host = "jyushin-backend.herokuapp.com"; 

//export const protocol= "http"; 
//export const host = "localhost"; 
//export const port = "8080"; 


//http://153.120.63.109:8080/
//export const protocol= "http"; 
//export const host = "153.120.63.109"; 
//export const port = "8080"; 



//https://jyushin-backend.izan-jp.com/
export const protocol= "https"; 
export const host = "jyushin-backend.izan-jp.com"; 